@import 'fonts.css';

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-width: 320px;
  /*  @TODO: not standard and should be removed (there is a task in Trello for this )*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}
* {
  -webkit-tap-highlight-color: transparent;
}

/* This code is needed to set the styles for the clipboard text.
  `copy-to-clipboard` lib create the <span> in <body> and set the text into it
  before putting into the buffer. Since it's the toplevel span it shouldn't
  affect other spans in the app. https://github.com/sudodoki/copy-to-clipboard/blob/master/index.js#L30
*/
body > span {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #212124 !important;
}

#root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: grey;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  /* position: absolute;
  left: 2500px; */
}
.fade-enter.fade-enter-active {
  /* left: 0;
  position: absolute;
  transition: left 150ms ease-in; */
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* puts the google places autocomplete dropdown results above the bootstrap modal 1050 zindex. */
.pac-container {
  z-index: 10001 !important;
}
