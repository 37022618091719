@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-700.woff2') format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-600.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-500.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-400.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Melio';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src:
    url('../fonts/Melio/Melio.eot') format('embedded-opentype'),
    url('../fonts/Melio/Melio.woff') format('woff'),
    url('../fonts/Melio/Melio.ttf') format('truetype'),
    url('../fonts/Melio/Melio.svg') format('svg');
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Melio' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
